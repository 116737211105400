var spt;
(function (spt) {
    var Utils;
    (function (Utils) {
        var Maps;
        (function (Maps) {
            Maps.greateDecoder = function (scope) {
                var self = scope;

                //minified decoder for gm protobuf
                (function () {
                    'use strict';
                    var T, jb = this;

                    function kb(c, d, e) {
                        c = c.split(".");
                        e = e || jb;
                        c[0] in e || "undefined" == typeof e.execScript || e.execScript("var " + c[0]);
                        for (var h; c.length && (h = c.shift());) c.length || void 0 === d ? e[h] && e[h] !== Object.prototype[h] ? e = e[h] : e = e[h] = {} : e[h] = d
                    }
                    var Fb = Date.now || function () {
                        return +new Date
                    };

                    function Mb(c) {
                        this.length = c.length || c;
                        for (var d = 0; d < this.length; d++) this[d] = c[d] || 0
                    }
                    Mb.prototype.a = 4;
                    Mb.prototype.set = function (c, d) {
                        d = d || 0;
                        for (var e = 0; e < c.length && d + e < this.length; e++) this[d + e] = c[e]
                    };
                    Mb.prototype.toString = Array.prototype.join;
                    "undefined" == typeof Float32Array && (Mb.BYTES_PER_ELEMENT = 4, Mb.prototype.BYTES_PER_ELEMENT = Mb.prototype.a, Mb.prototype.set = Mb.prototype.set, Mb.prototype.toString = Mb.prototype.toString, kb("Float32Array", Mb, void 0));

                    function Rb(c) {
                        this.length = c.length || c;
                        for (var d = 0; d < this.length; d++) this[d] = c[d] || 0
                    }
                    Rb.prototype.a = 8;
                    Rb.prototype.set = function (c, d) {
                        d = d || 0;
                        for (var e = 0; e < c.length && d + e < this.length; e++) this[d + e] = c[e]
                    };
                    Rb.prototype.toString = Array.prototype.join;
                    if ("undefined" == typeof Float64Array) {
                        try {
                            Rb.BYTES_PER_ELEMENT = 8
                        } catch (c) { }
                        Rb.prototype.BYTES_PER_ELEMENT = Rb.prototype.a;
                        Rb.prototype.set = Rb.prototype.set;
                        Rb.prototype.toString = Rb.prototype.toString;
                        kb("Float64Array", Rb, void 0)
                    };

                    function Sb() {
                        this.matrixMeshFromGlobe = this.matrixGlobeFromMesh = null;
                        this.meshes = [];
                        this.overlaySurfaceMeshes = [];
                        this.copyrightIds = this.waterMesh = null;
                        this.nonEmptyOctants = 0;
                        this.bvhTriPermutation = this.bvhNodes = null
                    }

                    function Tb() {
                        this.vertexAlphas = this.indices = this.uvOffsetAndScale = this.layerBounds = this.texture = this.vertices = null;
                        this.numNonDegenerateTriangles = 0;
                        this.meshId = -1;
                        this.octantCounts = this.normals = null
                    }

                    function Ub() {
                        this.bytes = null;
                        this.textureFormat = 1;
                        this.viewDirection = this.height = this.width = 0;
                        this.meshId = -1
                    }

                    function Vb() {
                        this.headNodePath = "";
                        this.obbRotations = this.obbExtents = this.obbCenters = this.metersPerTexel = this.flags = this.bulkMetadataEpoch = this.epoch = this.childIndices = null;
                        this.defaultImageryEpoch = 0;
                        this.imageryEpochArray = null;
                        this.defaultTextureFormat = 0;
                        this.textureFormatArray = null;
                        this.defaultAvailableViewDirections = 0;
                        this.childBulkMetadata = this.nodes = this.viewDependentTextureFormatArray = this.availableViewDirectionsArray = null
                    }

                    function Wb() {
                        this.textures = [];
                        this.transformInfo = [];
                        this.projectionOrigin = null
                    }

                    function pc() {
                        this.vertexTransformMap = this.transformTable = null;
                        this.meshId = -1;
                        this.uvOffsetAndScale = null
                    };

                    function qc(c) {
                        this.u = new DataView(c.buffer);
                        this.b = c;
                        this.a = 0;
                        this.c = c.length;
                        this.h = [];
                        this.l = this.o = 0
                    }
                    T = qc.prototype;
                    T.D = function () {
                        if (this.a < this.c) {
                            var c = this.sa();
                            this.o = c & 7;
                            return this.l = c >> 3
                        }
                        return 0
                    };
                    T.O = function (c) {
                        this.h.push(this.c);
                        this.c = c
                    };
                    T.N = function () {
                        if (!this.h.length) return !1;
                        this.c = this.h.pop();
                        return !0
                    };
                    T.sa = function () {
                        var c = 0,
                            d = 1;
                        do {
                            var e = this.b[this.a++];
                            c += (127 & e) * d;
                            d *= 128
                        } while (e & 128);
                        return c
                    };
                    T.jd = function () {
                        do var c = this.b[this.a++]; while (c & 128)
                    };
                    T.hd = function () {
                        this.a = this.sa() + this.a
                    };
                    T.B = function () {
                        switch (this.o) {
                            case 0:
                                return this.jd(), !0;
                            case 1:
                                return this.a += 8, !0;
                            case 2:
                                return this.hd(), !0;
                            case 5:
                                return this.a += 4, !0
                        }
                        return !1
                    };
                    T.Xc = function () {
                        var c = this.sa();
                        return c & 2147483648 ? (c & 2147483647) - 2147483648 : c
                    };
                    T.f = qc.prototype.sa;
                    T.$ = function () {
                        var c = this.u.getFloat32(this.a, !0);
                        this.a += 4;
                        return c
                    };
                    T.ua = function () {
                        var c = this.u.getFloat64(this.a, !0);
                        this.a += 8;
                        return c
                    };
                    T.Ia = function () {
                        return this.b[this.a++]
                    };
                    T.Ka = function () {
                        var c = this.b[this.a++];
                        c |= this.b[this.a++] << 8;
                        return c & 32768 ? c | 4294901760 : c
                    };
                    T.ia = function () {
                        var c = this.b[this.a++];
                        return this.b[this.a++] << 8 | c
                    };
                    T.cd = function () {
                        var c = this.b[this.a++];
                        c = this.a + c;
                        for (var d = ""; this.a < c;) {
                            var e = this.b[this.a++];
                            if (128 > e) d += String.fromCharCode(e);
                            else if (!(192 > e))
                                if (224 > e) {
                                    var h = this.b[this.a++];
                                    d += String.fromCharCode((e & 31) << 6 | h & 63)
                                } else if (240 > e) {
                                    h = this.b[this.a++];
                                    var k = this.b[this.a++];
                                    d += String.fromCharCode((e & 15) << 12 | (h & 63) << 6 | k & 63)
                                }
                        }
                        return d
                    };
                    T.data = function () {
                        return this.b
                    };
                    T.Oc = function () {
                        this.a = this.l = this.o = 0;
                        this.c = this.b.length;
                        this.h = []
                    };
                    T.U = function (c) {
                        this.a += c
                    };

                    function rc(c, d) {
                        this.h = new qc(c);
                        this.Oa = d;
                        this.c = null;
                        this.b = new Float64Array(3);
                        this.a = new Float32Array(4);
                        this.o = {};
                        this.l = this.u = 0;
                        this.ra = this.L = this.ba = this.M = !1
                    }
                    T = rc.prototype;
                    T.Nc = function () {
                        var c = this.h,
                            d, e = [],
                            h = this.c = new Vb;
                        this.a[0] = Infinity;
                        this.a[1] = Infinity;
                        this.a[2] = Infinity;
                        this.a[3] = Infinity;
                        for (h.defaultTextureFormat = 6; d = c.D();) switch (d) {
                            case 1:
                                d = c.f();
                                c.O(c.a + d);
                                e.push(this.bd());
                                c.N();
                                break;
                            case 2:
                                d = c.f();
                                c.O(c.a + d);
                                this.Vc();
                                c.N();
                                break;
                            case 3:
                                c.f();
                                this.b[0] = c.ua();
                                this.b[1] = c.ua();
                                this.b[2] = c.ua();
                                break;
                            case 4:
                                c.f();
                                this.a[0] = c.$();
                                this.a[1] = c.$();
                                this.a[2] = c.$();
                                this.a[3] = c.$();
                                break;
                            case 5:
                                h.defaultImageryEpoch = c.f();
                                break;
                            case 6:
                                h.defaultTextureFormat = sc(c.f());
                                break;
                            case 7:
                                h.defaultAvailableViewDirections = c.f();
                                break;
                            case 8:
                                h.defaultViewDependentTextureFormat = sc(c.f());
                                break;
                            default:
                                c.B()
                        }
                        c.Oc();
                        d = e.slice();
                        d.sort(tc);
                        for (var k = 0; k < e.length; k++) this.o[d[k]] = k;
                        k = e.length;
                        h.epoch = new Uint32Array(k);
                        h.bulkMetadataEpoch = new Uint32Array(k);
                        h.flags = new Uint8Array(k);
                        h.metersPerTexel = new Float32Array(k);
                        h.obbCenters = new Float64Array(3 * k);
                        h.obbExtents = new Float32Array(3 * k);
                        h.obbRotations = new Float32Array(9 * k);
                        h.imageryEpochArray = new Uint32Array(k);
                        h.textureFormatArray = new Uint8Array(k);
                        h.viewDependentTextureFormatArray = new Uint8Array(k);
                        h.availableViewDirectionsArray = new Uint8Array(k);
                        for (k = 0; d = c.D();) switch (d) {
                            case 1:
                                d = c.f();
                                c.O(c.a + d);
                                this.$c(e[k++]);
                                c.N();
                                break;
                            default:
                                c.B()
                        }
                        this.pc();
                        c = [h.childIndices.buffer, h.epoch.buffer, h.bulkMetadataEpoch.buffer, h.flags.buffer, h.metersPerTexel.buffer, h.obbCenters.buffer, h.obbExtents.buffer, h.obbRotations.buffer];
                        this.M ? c.push(h.imageryEpochArray.buffer) : h.imageryEpochArray = null;
                        this.ba ? c.push(h.textureFormatArray.buffer) : h.textureFormatArray = null;
                        this.L ? c.push(h.availableViewDirectionsArray.buffer) : h.availableViewDirectionsArray = null;
                        this.ra ? c.push(h.viewDependentTextureFormatArray.buffer) : h.viewDependentTextureFormatArray = null;
                        this.Oa(h, c)
                    };

                    function tc(c, d) {
                        var e = c.length - d.length;
                        return 0 != e ? e : c < d ? -1 : 1
                    }
                    T.Vc = function () {
                        for (var c = this.h, d = this.c, e; e = c.D();) switch (e) {
                            case 1:
                                d.headNodePath = c.cd();
                                break;
                            case 2:
                                this.l = c.f();
                                break;
                            default:
                                c.B()
                        }
                    };
                    T.bd = function () {
                        for (var c = this.h, d, e = ""; d = c.D();) switch (d) {
                            case 1:
                                e = c.f();
                                d = (e & 3) + 1;
                                e = e >> 2 & (1 << 3 * d) - 1;
                                for (var h = "", k = 0; k < d; k++) h += e >> 3 * k & 7;
                                e = h;
                                break;
                            default:
                                c.B()
                        }
                        return e
                    };
                    T.$c = function (c) {
                        var d = this.h,
                            e = this.c,
                            h, k = 0,
                            g = [],
                            m = [],
                            p = [],
                            v = 0,
                            z = this.l,
                            B = this.l,
                            A = this.o[c];
                        e.imageryEpochArray[A] = e.defaultImageryEpoch;
                        e.textureFormatArray[A] = e.defaultTextureFormat;
                        for (e.availableViewDirectionsArray[A] = e.defaultAvailableViewDirections; h = d.D();) switch (h) {
                            case 1:
                                k = d.f();
                                break;
                            case 3:
                                d.f();
                                g[0] = d.Ka();
                                g[1] = d.Ka();
                                g[2] = d.Ka();
                                m[0] = d.Ia();
                                m[1] = d.Ia();
                                m[2] = d.Ia();
                                p[0] = d.ia();
                                p[1] = d.ia();
                                p[2] = d.ia();
                                break;
                            case 4:
                                v = d.$();
                                break;
                            case 2:
                                z = d.f();
                                break;
                            case 5:
                                B = d.f();
                                break;
                            case 7:
                                this.M = !0;
                                e.imageryEpochArray[A] = d.f();
                                break;
                            case 8:
                                this.ba = !0;
                                e.textureFormatArray[A] = sc(d.f());
                                break;
                            case 9:
                                this.L = !0;
                                e.availableViewDirectionsArray[A] = d.f();
                                break;
                            case 10:
                                this.ra = !0;
                                e.viewDependentTextureFormatArray[A] = sc(d.f());
                                break;
                            default:
                                d.B()
                        }
                        c = c.length;
                        4 > c && this.u++;
                        e.epoch[A] = z;
                        e.bulkMetadataEpoch[A] = B;
                        e.flags[A] = k >> 2 + 3 * c;
                        0 == v && (v = this.a[c - 1]);
                        e.metersPerTexel[A] = v;
                        g[0] = g[0] * v + this.b[0];
                        g[1] = g[1] * v + this.b[1];
                        g[2] = g[2] * v + this.b[2];
                        m[0] *= v;
                        m[1] *= v;
                        m[2] *= v;
                        p[0] = p[0] * Math.PI / 32768;
                        p[1] = p[1] * Math.PI / 65536;
                        p[2] = p[2] * Math.PI / 32768;
                        k = new Float32Array(9);
                        z = p[0];
                        c = p[1];
                        v = p[2];
                        p = Math.cos(z);
                        z = Math.sin(z);
                        B = Math.cos(c);
                        c = Math.sin(c);
                        d = Math.cos(v);
                        v = Math.sin(v);
                        k[0] = p * d - B * z * v;
                        k[1] = B * p * v + d * z;
                        k[2] = v * c;
                        k[3] = -p * v - d * B * z;
                        k[4] = p * B * d - z * v;
                        k[5] = d * c;
                        k[6] = c * z;
                        k[7] = -p * c;
                        k[8] = B;
                        k == k ? (p = k[1], v = k[2], z = k[5], k[1] = k[3], k[2] = k[6], k[3] = p, k[5] = k[7], k[6] = v, k[7] = z) : (k[0] = k[0], k[1] = k[3], k[2] = k[6], k[3] = k[1], k[4] = k[4], k[5] = k[7], k[6] = k[2], k[7] = k[5], k[8] = k[8]);
                        e.obbCenters.set(g, 3 * A);
                        e.obbRotations.set(k, 9 * A);
                        e.obbExtents.set(m, 3 * A)
                    };
                    T.pc = function () {
                        this.c.childIndices = new Int16Array(8 * (this.u + 1));
                        this.Ab("", -1)
                    };
                    T.Ab = function (c, d) {
                        if (4 != c.length)
                            for (var e = 0; 8 > e; e++) {
                                var h = c + e,
                                    k = this.o[h];
                                void 0 !== k ? this.Ab(h, k) : k = -1;
                                this.c.childIndices[8 * (d + 1) + e] = k
                            }
                    };
                    var uc = [6, 1];

                    function sc(c) {
                        for (var d = 0; d < uc.length; d++) {
                            var e = uc[d];
                            if (c & 1 << e - 1) return 0 == e && c.toString(16), e
                        }
                        c.toString(16);
                        return uc[0]
                    };

                    function vc(c, d) {
                        return 0 == d ? c + 1 & -2 : 1 == d ? c | 1 : c + 2
                    };

                    function zd(c) {
                        this.ba = c;
                        this.l = null;
                        this.o = 0;
                        this.b = this.h = this.L = this.M = this.c = this.u = null;
                        this.a = 0
                    }
                    T = zd.prototype;
                    T.start = function () {
                        for (var c = this.ba, d = 0, e = 0; e < c.length; ++e) d += c[e].numNonDegenerateTriangles;
                        if (0 >= d) return null;
                        this.M = new Uint32Array(d);
                        this.c = new Uint32Array(d);
                        this.L = new Uint8Array(6 * d);
                        this.h = Array(3);
                        this.h[0] = new Uint8Array(d);
                        this.h[1] = new Uint8Array(d);
                        this.h[2] = new Uint8Array(d);
                        for (var h = this.L, k = this.h, g, m, p, v, z, B, A = 0, G = 0, C = 0, R = 0, L = 0, O = 0, sa = 0, ka = 0, qa = 0; qa < c.length; ++qa) {
                            e = c[qa];
                            var P = e.indices,
                                la = e.vertices,
                                Ea = P.length - 2;
                            for (e = 0; e < Ea; ++e) {
                                m = P[vc(e, 0)];
                                var J = P[vc(e, 1)],
                                    aa = P[vc(e, 2)];
                                if (m != J && J != aa && aa != m) {
                                    this.M[ka] = qa << 24 | e;
                                    this.c[ka] = ka;
                                    p = 8 * m;
                                    g = v = la[p++];
                                    m = z = la[p++];
                                    p = B = la[p];
                                    var Ja = 8 * J;
                                    J = la[Ja++];
                                    J < g ? g = J : J > v && (v = J);
                                    J = la[Ja++];
                                    J < m ? m = J : J > z && (z = J);
                                    J = la[Ja];
                                    J < p ? p = J : J > B && (B = J);
                                    aa *= 8;
                                    J = la[aa++];
                                    J < g ? g = J : J > v && (v = J);
                                    J = la[aa++];
                                    J < m ? m = J : J > z && (z = J);
                                    J = la[aa];
                                    J < p ? p = J : J > B && (B = J);
                                    h[A++] = g;
                                    h[A++] = m;
                                    h[A++] = p;
                                    h[A++] = v;
                                    h[A++] = z;
                                    h[A++] = B;
                                    g = g + v >> 1;
                                    m = m + z >> 1;
                                    p = p + B >> 1;
                                    k[0][ka] = g;
                                    k[1][ka] = m;
                                    k[2][ka] = p;
                                    0 < ka ? (g < G ? G = g : g > L && (L = g), m < C ? C = m : m > O && (O = m), p < R ? R = p : p > sa && (sa = p)) : (G = L = g, C = O = m, R = sa = p);
                                    ++ka
                                }
                            }
                        }
                        this.l = c = new Uint8Array(24 * d);
                        this.u = new Uint32Array(c.buffer);
                        c[0] = G;
                        c[1] = C;
                        c[2] = R;
                        c[3] = L;
                        c[4] = O;
                        c[5] = sa;
                        this.b = new Uint32Array(3 * d);
                        this.a = 0;
                        this.b[this.a++] = 0;
                        this.b[this.a++] = 0;
                        this.b[this.a++] = d;
                        this.o = 1;
                        return this.cb
                    };
                    T.cb = function () {
                        for (var c = this.b, d = 0; 0 < this.a;) {
                            var e = c[--this.a],
                                h = c[--this.a],
                                k = c[--this.a],
                                g = e - h;
                            if (0 == d || 1E4 > d + g) this.nc(k, h, e), d += g;
                            else {
                                this.a += 3;
                                break
                            }
                        }
                        return 0 == this.a ? this.wc : this.cb
                    };
                    T.nc = function (c, d, e) {
                        var h = e - d;
                        if (4 >= h) this.ob(c, d, e);
                        else {
                            var k = this.l,
                                g = 12 * c,
                                m = k[g + 3] - k[g + 0],
                                p = k[g + 4] - k[g + 1],
                                v = k[g + 5] - k[g + 2];
                            if (m > p && m > v) var z = 0;
                            else p > v ? (z = 1, m = p) : (z = 2, m = v);
                            k = k[g + z] + (m >> 1);
                            p = d;
                            v = e;
                            g = this.h[z];
                            for (m = this.c; ;) {
                                for (; p < v && !(g[m[p]] >= k);) p++;
                                for (; p < v && !(g[m[v - 1]] < k);) v--;
                                if (p == v) {
                                    p = v;
                                    break
                                }
                                var B = m[p];
                                m[p] = m[v - 1];
                                m[v - 1] = B
                            }
                            if (p == d || p == e) {
                                if (255 > h) {
                                    this.ob(c, d, e);
                                    return
                                }
                                p = (d + e) / 2;
                                k = g[m[p]]
                            }
                            h = this.o++;
                            g = this.o++;
                            this.Fc(c, h, z, k, d, p, e);
                            this.b[this.a++] = g;
                            this.b[this.a++] = p;
                            this.b[this.a++] = e;
                            this.b[this.a++] = h;
                            this.b[this.a++] = d;
                            this.b[this.a++] = p
                        }
                    };
                    T.Fc = function (c, d, e, h, k, g, m) {
                        h = this.l;
                        c *= 12;
                        h[c + 6] = 0;
                        h[c + 7] = e;
                        this.u[c + 8 >> 2] = d;
                        e = this.h[0];
                        c = this.h[1];
                        for (var p = this.h[2], v = 0; 2 > v; v++) {
                            var z = 0 == v ? k : g,
                                B = 0 == v ? g : m;
                            if (!(4 >= B - z)) {
                                var A = this.c[z],
                                    G = e[A],
                                    C = c[A];
                                A = p[A];
                                var R = G,
                                    L = G,
                                    O = C,
                                    sa = C,
                                    ka = A,
                                    qa = A;
                                for (z += 1; z < B; z++) A = this.c[z], G = e[A], C = c[A], A = p[A], G < R ? R = G : G > L && (L = G), C < O ? O = C : C > sa && (sa = C), A < ka ? ka = A : A > qa && (qa = A);
                                B = 12 * (0 == v ? d : d + 1);
                                h[B++] = R;
                                h[B++] = O;
                                h[B++] = ka;
                                h[B++] = L;
                                h[B++] = sa;
                                h[B] = qa
                            }
                        }
                    };
                    T.ob = function (c, d, e) {
                        var h = this.L,
                            k = this.c,
                            g, m, p, v;
                        var z = g = m = 255;
                        var B = p = v = 0;
                        for (var A = d; A < e; ++A) {
                            var G = 6 * k[A],
                                C = h[G++];
                            z = z < C ? z : C;
                            C = h[G++];
                            g = g < C ? g : C;
                            C = h[G++];
                            m = m < C ? m : C;
                            C = h[G++];
                            B = B > C ? B : C;
                            C = h[G++];
                            p = p > C ? p : C;
                            C = h[G++];
                            v = v > C ? v : C
                        }
                        h = this.l;
                        c *= 12;
                        k = c + 0;
                        h[k++] = z;
                        h[k++] = g;
                        h[k++] = m;
                        h[k++] = B;
                        h[k++] = p;
                        h[k] = v;
                        h[c + 6] = 1;
                        h[c + 7] = e - d;
                        this.u[c + 8 >> 2] = d
                    };
                    T.wc = function () {
                        for (var c = this.l, d = this.u, e = this.o - 1; 0 <= e; e--) {
                            var h = 12 * e;
                            if (0 == c[h + 6]) {
                                var k = h + 0;
                                h = 12 * d[h + 8 >> 2];
                                for (var g = h + 12, m = 0; 3 > m; m++ , k++ , h++ , g++) {
                                    var p = c[h],
                                        v = c[g];
                                    c[k] = p <= v ? p : v;
                                    p = c[3 + h];
                                    v = c[3 + g];
                                    c[3 + k] = p >= v ? p : v
                                }
                            }
                        }
                        for (e = 0; e < this.c.length; ++e) this.c[e] = this.M[this.c[e]];
                        this.l = c.subarray(0, 12 * this.o);
                        return null
                    };
                    T.hc = function () {
                        return this.l
                    };
                    var Ad = null;

                    function Cd(c, d, e) {
                        this.b = new qc(c);
                        this.o = d;
                        this.a = new Wb;
                        this.l = e
                    }
                    Cd.prototype.c = function () {
                        for (var c = this.b, d; d = c.D();) switch (d) {
                            case 2:
                                d = c.f();
                                c.O(c.a + d);
                                this.a.textures.push(Dd(c, this.o));
                                c.N();
                                break;
                            case 3:
                                d = c.f();
                                c.O(c.a + d);
                                d = this.h();
                                this.a.transformInfo[d.meshId] = d;
                                c.N();
                                break;
                            case 4:
                                var e = c.f() / 4;
                                this.a.projectionOrigin = new Float32Array(e);
                                for (d = 0; d < e; ++d) this.a.projectionOrigin[d] = c.$();
                                break;
                            default:
                                c.B()
                        }
                        c = [];
                        e = this.a.textures;
                        for (d = 0; d < e.length; d++) c.push(e[d].bytes.buffer);
                        for (d = 0; d < this.a.transformInfo.length; ++d)
                            if (this.a.transformInfo[d]) {
                                c.push(this.a.transformInfo[d].transformTable.buffer);
                                c.push(this.a.transformInfo[d].vertexTransformMap.buffer);
                                var h = e[this.a.transformInfo[d].meshId];
                                h = new Float32Array([.5, .5 - h.height, 1 / h.width, -1 / h.height]);
                                this.a.transformInfo[d].uvOffsetAndScale = h;
                                c.push(h.buffer)
                            } this.a.projectionOrigin && c.push(this.a.projectionOrigin.buffer);
                        this.l(this.a, c)
                    };
                    Cd.prototype.h = function () {
                        for (var c = this.b, d, e = new pc; d = c.D();) switch (d) {
                            case 1:
                                d = c.f();
                                for (var h = d / 6, k = new Float64Array(5 * h), g = c.data(), m = c.a, p = 0; p < h; ++p) {
                                    var v = m + 6 * p,
                                        z = g[v],
                                        B = g[v + 1],
                                        A = g[v + 2],
                                        G = g[v + 3],
                                        C = g[v + 4];
                                    v = g[v + 5];
                                    127 < G && (G -= 256);
                                    A += G << 8;
                                    127 < v && (v -= 256);
                                    C += v << 8;
                                    G = 2 * Math.PI * z / 256;
                                    z = Math.cos(G);
                                    G = Math.sin(G);
                                    B /= 255;
                                    k[5 * p] = 1 + (B - 1) * z * z;
                                    k[5 * p + 1] = (B - 1) * z * G;
                                    k[5 * p + 2] = 1 + (B - 1) * G * G;
                                    k[5 * p + 3] = A;
                                    k[5 * p + 4] = C
                                }
                                e.transformTable = k;
                                c.U(d);
                                break;
                            case 2:
                                d = c.f();
                                h = d / 2;
                                k = new Uint16Array(h);
                                g = c.data();
                                m = c.a;
                                for (p = 0; p < h; ++p) k[p] = g[m + 2 * p] + (g[m + 2 * p + 1] << 8);
                                e.vertexTransformMap = k;
                                c.U(d);
                                break;
                            case 3:
                                e.meshId = c.f();
                                break;
                            default:
                                c.B()
                        }
                        return e
                    };
                    var Ed = null;

                    function Dd(c, d) {
                        for (var e = c.data(), h, k = 1, g = 0, m = 0, p = 256, v = 256, z = 0, B = -1; h = c.D();) switch (h) {
                            case 1:
                                m = c.Xc();
                                g || (g = c.a);
                                c.U(m);
                                break;
                            case 3:
                                p = c.f();
                                break;
                            case 4:
                                v = c.f();
                                break;
                            case 2:
                                k = c.f();
                                break;
                            case 5:
                                z = c.f();
                                break;
                            case 6:
                                B = c.f();
                                break;
                            default:
                                c.B()
                        }
                        c = new Ub;
                        switch (k) {
                            case 1:
                                c.bytes = new Uint8Array(m);
                                c.bytes.set(e.subarray(g, g + m));
                                break;
                            case 6:
                                Ed || (Ed = new Maps.CrunchDecompressor());
                                h = Ed;
                                var A = h.malloc(m);
                                h.setData(e, g, m, A);
                                e = h.getDecompressedSize(A, m);
                                g = h.malloc(e);
                                c.bytes = new Uint8Array(e);
                                h.decompress(A, m, g, e);
                                h.getData(g, e, c.bytes, 0);
                                h.free(A);
                                h.free(g);
                                if (!d) {
                                    d = new Uint16Array(c.bytes.buffer);
                                    m = p;
                                    A = v;
                                    e = new Uint16Array(m * A);
                                    Ad || (Ad = new Uint16Array(4));
                                    g = Ad;
                                    h = m / 4;
                                    for (var G = A / 4, C = 0; C < G; C++)
                                        for (var R = 0; R < h; R++) {
                                            A = 4 * (C * h + R);
                                            g[0] = d[A];
                                            g[1] = d[A + 1];
                                            var L = g[0] & 31;
                                            var O = g[0] & 2016;
                                            var sa = g[0] & 63488;
                                            var ka = g[1] & 31;
                                            var qa = g[1] & 2016;
                                            var P = g[1] & 63488;
                                            g[2] = 5 * L + 3 * ka >> 3 | 5 * O + 3 * qa >> 3 & 2016 | 5 * sa + 3 * P >> 3 & 63488;
                                            g[3] = 5 * ka + 3 * L >> 3 | 5 * qa + 3 * O >> 3 & 2016 | 5 * P + 3 * sa >> 3 & 63488;
                                            O = 4 * C * m + 4 * R;
                                            L = d[A + 2];
                                            e[O] = g[L & 3];
                                            e[O + 1] = g[L >> 2 & 3];
                                            e[O + 2] = g[L >> 4 & 3];
                                            e[O + 3] = g[L >> 6 & 3];
                                            O += m;
                                            e[O] = g[L >> 8 & 3];
                                            e[O + 1] = g[L >> 10 & 3];
                                            e[O + 2] = g[L >> 12 & 3];
                                            e[O + 3] = g[L >> 14];
                                            L = d[A + 3];
                                            O += m;
                                            e[O] = g[L & 3];
                                            e[O + 1] = g[L >> 2 & 3];
                                            e[O + 2] = g[L >> 4 & 3];
                                            e[O + 3] = g[L >> 6 & 3];
                                            O += m;
                                            e[O] = g[L >> 8 & 3];
                                            e[O + 1] = g[L >> 10 & 3];
                                            e[O + 2] = g[L >> 12 & 3];
                                            e[O + 3] = g[L >> 14]
                                        }
                                    c.bytes = new Uint8Array(e.buffer)
                                }
                        }
                        c.textureFormat = k;
                        c.width = p;
                        c.height = v;
                        c.viewDirection = z;
                        c.meshId = B;
                        return c
                    };

                    function Fd(c, d, e, h) {
                        this.b = new qc(c);
                        this.u = d;
                        this.h = e;
                        this.a = new Sb;
                        this.l = [];
                        this.o = h;
                        this.c = null
                    }
                    T = Fd.prototype;
                    T.Fa = function () {
                        for (var c = this.b, d; d = c.D();) switch (d) {
                            case 1:
                                var e = c.f() / 8,
                                    h = this.a.matrixGlobeFromMesh = new Float64Array(16);
                                for (d = 0; d < e; d++) h[d] = c.ua();
                                this.a.matrixMeshFromGlobe = new Float64Array(16);
                                d = this.a.matrixMeshFromGlobe;
                                e = h[0];
                                var k = h[1],
                                    g = h[2],
                                    m = h[3],
                                    p = h[4],
                                    v = h[5],
                                    z = h[6],
                                    B = h[7],
                                    A = h[8],
                                    G = h[9],
                                    C = h[10],
                                    R = h[11],
                                    L = h[12],
                                    O = h[13],
                                    sa = h[14];
                                h = h[15];
                                var ka = e * v - k * p,
                                    qa = e * z - g * p,
                                    P = e * B - m * p,
                                    la = k * z - g * v,
                                    Ea = k * B - m * v,
                                    J = g * B - m * z,
                                    aa = A * O - G * L,
                                    Ja = A * sa - C * L,
                                    Ua = A * h - R * L,
                                    ab = G * sa - C * O,
                                    Ya = G * h - R * O,
                                    Va = C * h - R * sa,
                                    va = ka * Va - qa * Ya + P * ab + la * Ua - Ea * Ja + J * aa;
                                0 != va && (va = 1 / va, d[0] = (v * Va - z * Ya + B * ab) * va, d[1] = (-k * Va + g * Ya - m * ab) * va, d[2] = (O * J - sa * Ea + h * la) * va, d[3] = (-G * J + C * Ea - R * la) * va, d[4] = (-p * Va + z * Ua - B * Ja) * va, d[5] = (e * Va - g * Ua + m * Ja) * va, d[6] = (-L * J + sa * P - h * qa) * va, d[7] = (A * J - C * P + R * qa) * va, d[8] = (p * Ya - v * Ua + B * aa) * va, d[9] = (-e * Ya + k * Ua - m * aa) * va, d[10] = (L * Ea - O * P + h * ka) * va, d[11] = (-A * Ea + G * P - R * ka) * va, d[12] = (-p * ab + v * Ja - z * aa) * va, d[13] = (e * ab - k * Ja + g * aa) * va, d[14] = (-L * la + O * qa - sa * ka) * va, d[15] = (A * la - G * qa + C * ka) * va);
                                break;
                            case 2:
                                d = c.f();
                                c.O(c.a + d);
                                this.a.meshes.push(this.Ja());
                                c.N();
                                break;
                            case 3:
                                d = c.f();
                                this.a.copyrightIds ? this.a.copyrightIds.push(d) : this.a.copyrightIds = [d];
                                break;
                            case 6:
                                d = c.f();
                                c.O(c.a + d);
                                this.a.waterMesh = this.Ja();
                                c.N();
                                break;
                            case 7:
                                d = c.f();
                                c.O(c.a + d);
                                this.a.overlaySurfaceMeshes.push(this.Ja());
                                c.N();
                                break;
                            case 8:
                                this.h ? this.ad() : c.B();
                                break;
                            default:
                                c.B()
                        }
                        this.oc();
                        if (this.h)
                            for (d = 0; d < this.a.meshes.length; ++d) this.uc(this.a.meshes[d]);
                        c = [];
                        c.push(this.a.matrixGlobeFromMesh.buffer);
                        c.push(this.a.matrixMeshFromGlobe.buffer);
                        e = this.a.meshes;
                        for (d = 0; d < e.length; d++) c.push(e[d].vertices.buffer), c.push(e[d].uvOffsetAndScale.buffer), c.push(e[d].layerBounds.buffer), c.push(e[d].indices.buffer), e[d].normals && c.push(e[d].normals.buffer), (k = e[d].texture) && c.push(k.bytes.buffer);
                        e = this.a.overlaySurfaceMeshes;
                        for (d = 0; d < e.length; d++) c.push(e[d].vertices.buffer), c.push(e[d].layerBounds.buffer), c.push(e[d].indices.buffer), e[d].normals && c.push(e[d].normals.buffer);
                        if (d = this.a.waterMesh) c.push(d.vertices.buffer), c.push(d.vertexAlphas.buffer), c.push(d.layerBounds.buffer), c.push(d.indices.buffer), d.normals && c.push(d.normals.buffer);
                        c.push(this.a.bvhNodes.buffer);
                        c.push(this.a.bvhTriPermutation.buffer);
                        this.o(this.a, c)
                    };
                    T.oc = function () {
                        for (var c = this.a, d = c.meshes.slice(), e = 0; e < c.overlaySurfaceMeshes.length; e++) d.push(c.overlaySurfaceMeshes[e]);
                        c.waterMesh && d.push(c.waterMesh);
                        if (0 != d.length) {
                            c = new zd(d);
                            for (d = c.start(); null != d;) d = d.apply(c);
                            this.a.bvhNodes = c.hc();
                            this.a.bvhTriPermutation = c.c
                        }
                    };
                    T.Ja = function () {
                        var c = this.b,
                            d = new Tb,
                            e = [];
                        this.l.push(e);
                        for (var h; h = c.D();) switch (h) {
                            case 1:
                                this.fd(d);
                                break;
                            case 3:
                                this.Wc(d);
                                break;
                            case 6:
                                h = c.f();
                                c.O(c.a + h);
                                d.texture = Dd(c, this.u);
                                c.N();
                                break;
                            case 7:
                                this.dd(d);
                                break;
                            case 8:
                                this.Yc(d, e);
                                break;
                            case 9:
                                this.ed(d);
                                break;
                            case 10:
                                var k = c.f() / 4,
                                    g = d.uvOffsetAndScale = new Float32Array(4);
                                for (h = 0; h < k; h++) g[h] = c.$();
                                break;
                            case 11:
                                this.h ? this.Zc(d) : c.B();
                                break;
                            case 12:
                                d.meshId = c.f();
                                break;
                            default:
                                c.B()
                        }
                        d.uvOffsetAndScale && (d.uvOffsetAndScale[1] -= 1 / d.uvOffsetAndScale[3], d.uvOffsetAndScale[3] *= -1);
                        c = d.vertices;
                        k = d.indices;
                        for (var m = g = 0; m < e.length; m++) {
                            var p = m & 7;
                            0 < e[m] && (this.a.nonEmptyOctants |= 1 << p);
                            for (h = 0; h < e[m]; h++) {
                                var v = 8 * k[g++] + 3;
                                c[v] = p
                            }
                        }
                        return d
                    };
                    T.Wc = function (c) {
                        var d = this.b;
                        d.f();
                        for (var e = d.f(), h = c.indices = new Uint16Array(e), k = 0, g = 0, m, p = 0, v = 0, z = 0; z < e; z++) {
                            var B = d.f();
                            m = p;
                            p = v;
                            v = k - B;
                            h[z] = v;
                            m != p && p != v && m != v && g++;
                            B || k++
                        }
                        c.numNonDegenerateTriangles = g
                    };
                    T.fd = function (c) {
                        var d = this.b,
                            e = d.data(),
                            h = d.f(),
                            k = h / 3;
                        c = c.vertices = new Uint8Array(8 * k);
                        for (var g = d.a, m = 0; 2 >= m; m++) {
                            var p = e[g++];
                            c[m] = p;
                            for (var v = 1; v < k; v++) p = p + e[g++] & 255, c[8 * v + m] = p
                        }
                        d.U(h)
                    };
                    T.ed = function (c) {
                        var d = this.b,
                            e = d.data(),
                            h = d.f();
                        c = c.vertexAlphas = new Uint8Array(h);
                        var k = d.a,
                            g = e[k++];
                        c[0] = g;
                        for (var m = 1; m < h; m++) g = g + e[k++] & 255, c[m] = g;
                        d.U(h)
                    };
                    T.dd = function (c) {
                        for (var d = this.b, e = d.data(), h = (d.f() - 4) / 4, k = d.ia(), g = d.ia(), m = 0, p = 0, v = d.a, z = c.vertices, B = 0; B < h; B++) {
                            var A = e[v + 1 * h + B] + (e[v + 3 * h + B] << 8);
                            m = (m + (e[v + 0 * h + B] + (e[v + 2 * h + B] << 8))) % (k + 1);
                            p = (p + A) % (g + 1);
                            A = 8 * B + 4;
                            z[A + 0] = m & 255;
                            z[A + 1] = m >> 8;
                            z[A + 2] = p & 255;
                            z[A + 3] = p >> 8
                        }
                        c.uvOffsetAndScale || (c.uvOffsetAndScale = new Float32Array([.5, .5, 1 / (k + 1), 1 / (g + 1)]));
                        d.U(4 * h)
                    };
                    T.Yc = function (c, d) {
                        var e = this.b;
                        e.f();
                        var h = e.f(),
                            k = 0,
                            g = c.layerBounds = new Uint32Array(10),
                            m = 0;
                        c = c.octantCounts = new Uint32Array(72);
                        for (var p = 0; p < h; p++) {
                            0 == p % 8 && (g[m++] = k);
                            var v = e.f();
                            d.push(v);
                            c[8 * (m - 1) + (p & 7)] = v;
                            k += v
                        }
                        for (; 10 > m; m++) g[m] = k
                    };

                    function Gd(c, d) {
                        if (4 >= d) return (c << d) + (c & (1 << d) - 1);
                        if (6 >= d) {
                            var e = 8 - d;
                            return (c << d) + (c << d >> e) + (c << d >> e >> e) + (c << d >> e >> e >> e)
                        }
                        return -(c & 1)
                    }

                    function Hd(c, d) {
                        return c < d ? c : d
                    }

                    function Id(c) {
                        c = Math.round(c);
                        return Hd(0 > c ? 0 : c, 255)
                    }
                    T.ad = function () {
                        var c = this.b,
                            d = c.data(),
                            e = c.f(),
                            h = c.a,
                            k = d[h] + (d[h + 1] << 8),
                            g = d[h + 2];
                        h += 3;
                        this.c = new Uint8Array(3 * k);
                        for (var m = 0; m < e; ++m) {
                            var p = d[h + m],
                                v = d[h + k + m];
                            p = Gd(p, g);
                            v = Gd(v, g);
                            var z = p / 255,
                                B = v / 255;
                            v = this.c;
                            p = 3 * m;
                            var A = z,
                                G = B,
                                C = A + G,
                                R = A - G,
                                L = 1;
                            .5 <= C && 1.5 >= C && -.5 <= R && .5 >= R || (L = -1, .5 >= C ? (A = .5 - B, G = .5 - z) : 1.5 <= C ? (A = 1.5 - B, G = 1.5 - z) : -.5 >= R ? (A = B - .5, G = z + .5) : (A = B + .5, G = z - .5), C = A + G, R = A - G);
                            z = Hd(Hd(2 * C - 1, 3 - 2 * C), Hd(2 * R + 1, 1 - 2 * R)) * L;
                            A = 2 * A - 1;
                            G = 2 * G - 1;
                            B = 127 / Math.sqrt(z * z + A * A + G * G);
                            v[p + 0] = Id(B * z + 127);
                            v[p + 1] = Id(B * A + 127);
                            v[p + 2] = Id(B * G + 127)
                        }
                        c.U(e)
                    };
                    T.Zc = function (c) {
                        var d = this.b,
                            e = d.data(),
                            h = d.f(),
                            k = d.a;
                        c.normals = new Uint8Array(e.buffer.slice(k, k + h));
                        d.U(h)
                    };
                    T.uc = function (c) {
                        var d = c.normals;
                        if (d && this.c)
                            for (h = d.length / 2, c.normals = new Uint8Array(4 * h), k = 0; k < h; ++k) {
                                var e = d[k] + (d[h + k] << 8);
                                c.normals[4 * k] = this.c[3 * e];
                                c.normals[4 * k + 1] = this.c[3 * e + 1];
                                c.normals[4 * k + 2] = this.c[3 * e + 2];
                                c.normals[4 * k + 3] = 0
                            } else {
                            var h = c.vertices.length / 8;
                            c.normals = new Uint8Array(4 * h);
                            for (var k = 0; k < h; ++k) c.normals[4 * k] = 127, c.normals[4 * k + 1] = 127, c.normals[4 * k + 2] = 127, c.normals[4 * k + 3] = 0
                        }
                    };
                    var Jd = [];

                    function rf(c) {
                        this.b = c;
                        c.webkitPostMessage && (c.postMessage = c.webkitPostMessage);
                        var d = this;
                        c.onmessage = function (c) {
                            d.a(c)
                        }
                    }
                    rf.prototype.a = function (c) {
                        var d = Fb(),
                            e = c.data.id,
                            h = c.data.command;
                        c = c.data.payload;
                        var k = this.b;
                        if (void 0 !== e && void 0 !== h && c) {
                            c = new Uint8Array(c);
                            var g = function (c, g) {
                                var h = Fb() - d,
                                    m = {};
                                m.id = e;
                                m.time = h;
                                m.payload = c;
                                m.logs = [];
                                m.complete = !0;
                                k.postMessage(m, g)
                            };
                            0 == h ? (h = new rc(c, g), h.Nc()) : 1 == h ? (h = new Fd(c, !0, !1, g), h.Fa()) : 2 == h ? (h = new Fd(c, !1, !0, g), h.Fa()) : 3 == h ? (h = new Fd(c, !0, !0, g), h.Fa()) : 4 == h ? (h = new Cd(c, !0, g), h.c()) : 5 == h ? (h = new Cd(c, !1, g), h.c()) : Jd.push("Bad DecodeTaskCommand: " + h)
                        }
                    };
                    new rf(self);
                }).call(scope);
            };
        })(Maps = Utils.Maps || (Utils.Maps = {}));
    })(Utils = spt.Utils || (spt.Utils = {}));
})(spt || (spt = {}));
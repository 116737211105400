module spt.Utils.Maps {
    export var decodeResource = (() => {
        const callbacks = {};
        const self: {onmessage?: (data) => void; postMessage: (p, g) => void} = {
            postMessage(p, g) {
                callbacks[p.id].resolve(p);
                delete callbacks[(<any>event).id];
            }
        };

        Maps.greateDecoder(self);

        return async function decode(command, payload): Promise<any> {
            const id = Utils.GenerateGuid();
            return await new Promise((resolve, reject) => {
                callbacks[id] = { resolve, reject }
                self.onmessage({ data: { id, command, payload } });
            });
        }
    })();
}